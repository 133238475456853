import axios from '@/plugins/axios'
import axiosContact from '@/plugins/axios-contact'

export default {

  async login (data) {
    return await axios.post('login', data)
  },

  async loginContact (data) {
    return await axiosContact.post('login-contact', data)
  },

  async loginAnswerSurvey (id, data) {
    return await axiosContact.post(`answer-surveys/${id}/login`, data, { surveyId: id })
  }

}
